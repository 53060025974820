import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

import Layout from "@/components/admin/layout/Layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Login" },
    component: () => import("@/views/Home.vue"),
    meta: {
      public: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      public: true,
      guest: true,
      title: "ログイン"
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      public: true,
      guest: true,
      title: "パスワードを忘れた"
    }
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: {
      public: true,
      guest: true
    }
  },
  {
    path: "/admin",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "/",
        redirect: { name: "Login" }
      },
      {
        path: "/shops/:shop_id/mailing",
        name: "Mailing",
        component: () => import("@/views/admin/Mailing/Index"),
        meta: {
          title: "メーリングリスト"
        }
      },
      {
        path: "/shops/:shop_id/business",
        name: "Business",
        component: () => import("@/views/admin/Business/Edit"),
        meta: {
          title: "営業日設定"
        }
      },
      {
        path: "/shops/:shop_id/inventory-management",
        name: "InventoryManagement",
        component: () => import("@/views/admin/InventoryManagement/Index"),
        meta: {
          title: "棚卸管理"
        }
      },
      {
        path: "/shops/:shop_id/fixed-cost",
        name: "FixedCost",
        component: () => import("@/views/admin/FixedCost/Index"),
        meta: {
          title: "月コスト"
        }
      },
      {
        path: "/shops/:shop_id/goals",
        name: "PayableAccountsGoals",
        component: () => import("@/views/admin/Goals/Index"),
        meta: {
          title: "売上目標の設定"
        }
      },
      {
        path: "/shops/:shop_id/payable-accounts",
        name: "PayableAccountsPayable",
        component: () => import("@/views/admin/PayableAccounts/Index"),
        meta: {
          title: "買掛"
        }
      },
      {
        path: "/shops/:shop_id/cashbook",
        name: "CashbookList",
        component: () => import("@/views/admin/CashBook/Index"),
        meta: {
          title: "現金出納・小口"
        }
      },
      {
        path: "/shops",
        name: "Shops",
        component: () => import("@/views/admin/Shops/index"),
        meta: {
          title: "店舗一覧"
        }
      },
      {
        path: "/shops/create",
        name: "ShopsCreate",
        component: () => import("@/views/admin/Shops/Create"),
        meta: {
          title: "新規店舗の登録"
        }
      },
      {
        path: "/shops/:shop_id/edit",
        name: "ShopsEdit",
        component: () => import("@/views/admin/Shops/Edit"),
        meta: {
          title: "店舗の基本情報"
        }
      },
      {
        path: "/shops/:shop_id/users/:user_id",
        name: "UserShopEdit",
        component: () =>
          import("@/components/admin/partials/Shops/partials/EditUserShop"),
        meta: {
          title: "ユーザー情報"
        }
      },
      {
        path: "/notification/linkpost",
        name: "NotificationLinkPost",
        component: () => import("@/views/admin/Notification/LinkPost/index"),
        meta: {
          title: "リンク投稿"
        }
      },
      {
        path: "/users",
        name: "UserList",
        component: () => import("@/views/admin/User/List/index"),
        meta: {
          title: "ユーザー"
        }
      },
      {
        path: "/users/create",
        name: "UserCreate",
        component: () => import("@/views/admin/User/Create/index"),
        meta: {
          title: "新規ユーザー登録"
        }
      },
      {
        path: "/users/:id/edit",
        name: "UserEdit",
        component: () => import("@/views/admin/User/Edit/index"),
        meta: {
          title: "ユーザー情報編集"
        }
      },
      {
        path: "/notification/list",
        name: "NotificationList",
        component: () => import("@/views/admin/Notification/List/index"),
        meta: {
          title: "お知らせ一覧"
        }
      },
      {
        path: "/notification/draft",
        name: "NotificationDraft",
        component: () => import("@/views/admin/Notification/Draft/index"),
        meta: {
          title: "お知らせ一覧"
        }
      },
      {
        path: "/notification/category-tag",
        name: "NotificationLinkColumn",
        component: () => import("@/views/admin/Notification/CategoryTags/index")
      },
      {
        path: "/notification/create",
        name: "NotificationCreate",
        component: () => import("@/views/admin/Notification/Editor/index")
      },
      {
        path: "/notification/:id/edit",
        name: "NotificationEdit",
        component: () => import("@/views/admin/Notification/Editor/edit"),
        meta: {
          title: "お知らせ一覧"
        }
      },
      {
        path: "/admin-users",
        name: "AdminUsers",
        component: () => import("@/views/admin/Setting/AdminUser/List/index"),
        meta: {
          title: "管理者一覧"
        }
      },
      {
        path: "/admin-users/create",
        name: "AdminUsersCreate",
        component: () => import("@/views/admin/Setting/AdminUser/Create/index"),
        meta: {
          title: "新しい管理者の登録"
        }
      },
      {
        path: "/admin-users/:id/edit",
        name: "AdminUsersEdit",
        component: () => import("@/views/admin/Setting/AdminUser/Edit/index"),
        meta: {
          title: "管理者編集"
        }
      },
      {
        path: "/faq",
        name: "Faq",
        component: () => import("@/views/admin/Setting/Faq/List/index"),
        meta: {
          title: "FAQ"
        }
      },
      {
        path: "/faq/create",
        name: "FaqCreate",
        component: () => import("@/views/admin/Setting/Faq/Create/index"),
        meta: {
          title: "FAQ Create"
        }
      },
      {
        path: "/faq/:id/edit",
        name: "FaqEdit",
        component: () => import("@/views/admin/Setting/Faq/Edit/index"),
        meta: {
          title: "FAQを編集する"
        }
      },
      {
        path: "/shops/:shop_id/monthly-report",
        name: "MonthlyReportList",
        component: () => import("@/views/admin/Store/MonthlyReport/Index"),
        meta: {
          title: "月報一覧"
        }
      },
      {
        path: "/shops/:shop_id/monthly-report/:month",
        name: "MonthlyReportDetail",
        component: () => import("@/views/admin/Store/MonthlyReport/Detail"),
        meta: {
          title: "月報一覧"
        }
      },
      {
        path: "/shops/:shop_id/daily-report",
        name: "DailyReportList",
        component: () => import("@/views/admin/Store/DailyReport/Index"),
        meta: {
          title: "日報一覧"
        }
      },
      {
        path: "/shops/:shop_id/daily-report/:date",
        name: "DailyReportDetail",
        component: () => import("@/views/admin/Store/DailyReport/Detail")
      },
      {
        path: "/shops/:shop_id/supplier",
        name: "SupplierList",
        component: () => import("@/views/admin/Supplier/Index"),
        meta: {
          title: "取引先一覧"
        }
      },
      {
        path: "/shops/:shop_id/supplier/create",
        name: "SupplierCreate",
        component: () => import("@/views/admin/Supplier/Create"),
        meta: {
          title: "新規取引先の登録"
        }
      },
      {
        path: "/:shop_id/supplier/edit/:id",
        name: "SupplierEdit",
        component: () => import("@/views/admin/Supplier/Edit"),
        meta: {
          title: "取引先の基本情報"
        }
      },
      {
        path: "/test",
        name: "CalculationTests",
        component: () => import("@/views/test/Index")
      }
    ]
  },
  {
    path: "*",
    name: "Error",
    component: () => import("@/views/error/Error.vue"),
    meta: {
      public: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// Auth guard
router.beforeEach((to, from, next) => {
  document.title = "Cross Point 日報管理";
  const authRequired = !to.matched.some(record => record.meta.public);
  const loggedIn = store.getters.token;
  if (authRequired && !loggedIn) {
    next({
      path: "/login",
      query: {
        redirect: to.fullPath
      }
    });
  } else if (!authRequired && loggedIn) {
    next({
      name: "Shops"
    });
  }
  next();
});

// title
router.afterEach(to => {
  if (to.meta.title !== undefined) {
    document.title = `${to.meta.title} | ${document.title}`;
  }
});

export default router;
